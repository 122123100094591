<template>
  <div>
    <!-- @click="$oucy.replace('/Shop?enterpriseId='+enterpriseId, null)" -->

    <vipTop
      :enterpriseinfo="enterpriseinfo"
      @ourShop="ourShop"
      @openMessage="openMessage"
    ></vipTop>
    <div v-if="carouselList && carouselList.length">
      <!--轮播-->
      <el-carousel :height="bannerHeight + 'px'" indicator-position="outside">
        <el-carousel-item
          v-for="(item, index) in carouselList"
          class="carousel"
          :key="index"
        >
          <img
            :src="$oucy.ossUrl + item.adImg"
            alt=""
            :class="item.adLinkUrl ? 'pointer' : ''"
            @click="carouselClick(item, index)"
            :title="item.adText"
          />
        </el-carousel-item>
      </el-carousel>
      <img
        v-if="carouselList[0] && carouselList[0].adImg"
        :src="$oucy.ossUrl + carouselList[0].adImg"
        style="
          position: absolute;
          z-index: -99;
          width: 100%;
          height: auto;
          left: -10000px;
          top: -10000;
        "
        ref="adImg"
        id="adImg"
      />
    </div>
    <!-- <img src="@/assets/images/qwea.png" style="width: 100%;min-width: 1200px;"> -->
    <div class="product topBottom">
      <div
        :style="{ width: this.$oucy.pageWidth + 'px' }"
        style="margin-left: auto; margin-right: auto"
      >
        <div class="df-jc-s-b centerNav" v-if="0">
          <!--          <div-->
          <!--            class="nav"-->
          <!--            :class="{ selsct: navType == 'home' }"-->
          <!--            @click="navType = 'home'"-->
          <!--          >-->
          <!--            首页-->
          <!--            <span></span>-->
          <!--          </div>-->
          <div
            class="nav"
            :class="{ selsct: navType == 'spu' }"
            @click="navType = 'spu'"
          >
            商品
            <span></span>
          </div>
          <div class="nav" v-if="0">
            动态
            <span></span>
          </div>
          <div class="nav" v-if="0">
            会员
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <div
      :style="{ width: this.$oucy.pageWidth + 'px', display: 'inline-block' }"
    >
      <div v-if="navType == 'spu'">
        <div v-if="!hasSearch">
          <div class="middle" v-if="recommendSpu && recommendSpu.length">
            <div class="headline">
              主推产品
              <div class="headlinetext">Recommended product</div>
              <span class="headline_line"></span>
            </div>
            <div class="recommendSpu list4 m-t-20">
              <spu4List :isShowStock="false" :data="recommendSpu" />
            </div>
            <div class="m-t-85">
              <div v-if="carouselList && carouselList.length">
                <el-carousel
                  :height="bannerHeight2 + 'px'"
                  indicator-position="outside"
                >
                  <el-carousel-item
                    v-for="(item, index) in carouselList"
                    class="carousel"
                    :key="index"
                  >
                    <img :src="$oucy.ossUrl + item.adImg" alt="" />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
          <div class="middle" v-if="newSpu && newSpu.length">
            <div class="headline">
              新品推荐
              <div class="headlinetext">New Products</div>
              <span class="headline_line"></span>
            </div>
            <div class="grid list5">
              <spu5List :isShowStock="false" :data="newSpu" />
            </div>
          </div>
          <div class="middle" v-if="hotSpu && hotSpu.length">
            <div class="headline"></div>
            <div class="headline">
              热销产品
              <div class="headlinetext">hot-sale products</div>
              <span class="headline_line"></span>
            </div>
            <div class="grid m-t-20">
              <div class="hotSpu">
                <div class="df hotSaleProducts">
                  <div
                    v-for="(v, i) in hotSpu"
                    v-if="i < 2"
                    :key="i"
                    class="hotSaleProduct pointer"
                    @click="$oucy.go('/product?spuId=' + v.id)"
                  >
                    <img
                      :src="$oucy.ossUrl + v.cover"
                      class="hotSaleProduct_img"
                    />
                    <div class="hotSaleProducts_btm">
                      <!--                                            <div class="c-m">￥{{v.spuPrise}}</div>-->
                      <div class="c-f m-l-10 line-1">{{ v.spuName }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <spu4List class="list4" :isShowStock="false" :data="hotSpu" />
            </div>
          </div>
        </div>
        <div v-if="hasSearch">
          <div
            class="middle"
            v-if="
              queryFurnitureLibraryObj &&
              queryFurnitureLibraryObj.content &&
              queryFurnitureLibraryObj.content.length
            "
          >
            <div class="grid">
              <ul>
                <li
                  v-for="v in queryFurnitureLibraryObj.content"
                  @click="$oucy.go('/product?spuId=' + v.id)"
                  :key="v.id"
                >
                  <!-- <video :src="$oucy.ossUrl+v.spuVideo" v-if="v.spuVideo " class="spuImgs" controls=""></video> -->
                  <img
                    :src="$oucy.ossUrl + v.spuImgs[0].imgUrl"
                    v-if="v.spuImgs && v.spuImgs[0] && v.spuImgs[0].imgUrl"
                    class="spuImgs"
                  />
                  <div class="spuImgs" v-else></div>
                  <div>
                    <p class="title line-1">{{ v.spuName }}</p>
                  </div>
                  <el-row>
                    <el-col :span="12">
                      <span class="price" v-if="v.spuMinPrise * 1 != 0">
                        ￥{{ v.spuMinPrise }}
                      </span>
                      <span class="price" v-else>面议</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="stock" style="float: right">
                        库存&nbsp{{ v.quantity }}
                      </span>
                    </el-col>
                  </el-row>
                  <div class="brand_name">
                    <span style="line-height: 15px" v-if="v.brand">
                      {{ v.brand.brandName }}
                    </span>
                  </div>
                  <div class="add">
                    <el-button type="text">加入进货车</el-button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            v-if="queryFurnitureLibraryObj.content"
            background
            layout="sizes, prev, pager, next, jumper, ->, total, slot"
            :page-size="queryFurnitureLibraryObj.size"
            :total="queryFurnitureLibraryObj.totalElements"
            @size-change="sizeChange"
            @current-change="currentChange"
            @prev-click="currentChange"
            :page-sizes="pageSizes"
            @next-click="currentChange"
          ></el-pagination>
        </div>
      </div>
      <div v-if="navType == 'spu'">
        <div class="middle_bottom m-t-50">
          <el-row>
            <el-col :span="5">
              <!-- <table class="store_kind">
                <th>产品分类</th>
                <tr
                  v-for="(v, i) of byLtUserAuthAndClassifyCategoryList"
                  @click="setClassify(v)"
                  class="pointer"
                  :key="v.id"
                >
                  <td>
                    <el-button
                      type="text"
                      :class="{
                        selsct: v.id == queryFurnitureLibrary2.classifyId,
                      }"
                    >
                      {{ v.classifyName }}
                    </el-button>
                  </td>
                </tr>
              </table> -->
              <myMenu
                :list="byLtUserAuthAndClassifyCategoryList"
                :default-active="queryFurnitureLibrary2.classifyId"
                @menuSelect="setClassify"
              >
                <span
                  class="bold_span m-b-20"
                  style="
                    padding-top: 30px;
                    padding-left: 20px;
                    display: inline-block;
                  "
                >
                  产品分类
                </span>
              </myMenu>
              <table class="customer_service">
                <th>店内客服</th>
                <tr
                  v-for="(v, i) of enterpriseCustomer"
                  @click="openImMessage(v)"
                  :key="v.id"
                >
                  <td class="df-ai-c p-l-15">
                    <img
                      :src="$oucy.ossUrl + v.detailAvatar"
                      v-if="v.detailAvatar"
                    />
                    <img
                      src="../../assets/icon/customer_service_icon.png"
                      v-else
                    />
                    <el-button
                      type="text"
                      class="m-l-10 line-1"
                      style="width: 120px"
                      :title="v.customerNickname || v.detailNickname"
                    >
                      {{ v.customerNickname || v.detailNickname }}
                    </el-button>
                  </td>
                </tr>
              </table>
              <table class="sales_rank" v-if="spuRankingWeight && 0">
                <th class="text-center">
                  <div style="width: 210px; margin-top: 2px">
                    <img src="../../assets/icon/crown.png" />
                  </div>
                  -销量排行-
                </th>
                <tr
                  v-for="(v, i) of spuRankingWeight"
                  @click="$oucy.go('/product?spuId=' + v.id)"
                  :key="v.id"
                >
                  <td>
                    <img
                      :src="$oucy.ossUrl + v.cover"
                      style="width: 188px; height: 188px"
                    />
                    <div class="goods_name line-1">
                      <span>{{ v.spuName }}</span>
                    </div>
                  </td>
                </tr>
              </table>
            </el-col>
            <el-col :span="19">
              <div class="right_top df-jc-s-b">
                <div class="f16">
                  <span class="line-h"></span>
                  {{ queryFurnitureLibrary2.classifyName }}
                </div>
                <div class="df-jc-s-b df-ai-c">
                  <span class="f14">价格</span>
                  <el-input
                    size="mini"
                    v-model.number="queryFurnitureLibrary2.priceMin"
                    class="inputprice m-l-10"
                  ></el-input>
                  <span class="m-l-5">-</span>
                  <el-input
                    size="mini"
                    v-model.number="queryFurnitureLibrary2.priceMax"
                    class="inputprice m-l-5"
                  ></el-input>
                  <el-button
                    type="primary"
                    class="m-l-10"
                    size="mini"
                    @click="innerSearchSpu"
                  >
                    确定
                  </el-button>
                </div>
                <div class="df-ai-c">
                  <span class="f14">排序</span>
                  <el-button
                    class="m-l-10"
                    :type="
                      queryFurnitureLibrary2.goodSort == 0 ? 'primary' : 'info'
                    "
                    plain
                    size="mini"
                    @click="setGoodSort(0)"
                  >
                    销量
                  </el-button>
                  <el-button
                    :type="
                      queryFurnitureLibrary2.goodSort == 1 ? 'primary' : 'info'
                    "
                    plain
                    size="mini"
                    @click="setGoodSort(1)"
                  >
                    价格
                  </el-button>
                  <el-button
                    :type="
                      queryFurnitureLibrary2.goodSort == 2 ? 'primary' : 'info'
                    "
                    plain
                    size="mini"
                    @click="setGoodSort(2)"
                  >
                    时间
                  </el-button>
                  <el-button
                    :type="
                      queryFurnitureLibrary2.goodSort == 3 ? 'primary' : 'info'
                    "
                    plain
                    size="mini"
                    @click="setGoodSort(3)"
                  >
                    库存
                  </el-button>
                  <el-button
                    :type="
                      queryFurnitureLibrary2.goodSort == 4 ? 'primary' : 'info'
                    "
                    plain
                    size="mini"
                    @click="setGoodSort(4)"
                  >
                    浏览量
                  </el-button>
                </div>
              </div>
              <div
                class="middle"
                v-if="
                  queryFurnitureLibraryObj2 &&
                  queryFurnitureLibraryObj2.content &&
                  queryFurnitureLibraryObj2.content.length
                "
              >
                <div class="grid">
                  <div class="navTypeSpu">
                    <div
                      class="navTypeSpu_items"
                      v-for="v in queryFurnitureLibraryObj2.content"
                      :key="v.id"
                    >
                      <div class="navTypeSpu_item">
                        <spu :data="v" :isShowStock="false"></spu>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <NoData v-else></NoData>
              <div class="pagination">
                <el-pagination
                  v-if="queryFurnitureLibraryObj2.content"
                  background
                  layout="sizes, prev, pager, next, jumper, ->, total, slot"
                  :page-size="queryFurnitureLibraryObj2.size"
                  :total="queryFurnitureLibraryObj2.totalElements"
                  @size-change="sizeChange2"
                  @current-change="currentChange2"
                  :page-sizes="pageSizes"
                  @prev-click="currentChange2"
                  @next-click="currentChange2"
                ></el-pagination>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="text-center m-b-50">
      <img src="@/assets/images/product/logo.png" class="m-r-15" />
      <img src="@/assets/images/product/VIPban.png" />
    </div>
    <enterpriseCoupon
      v-if="enterpriseId"
      :enterpriseId="enterpriseId"
    ></enterpriseCoupon>
  </div>
</template>
<script>
  import oucy from "@/util/oucyUtil";
  import { localSet, localGet, localDel } from "@/store/store";
  import {
    spu,
    furnitureClassify,
    enterpriseinfo,
    adEnterprise,
    cardEnterprise,
  } from "@/service";
  import vipTop from "./components/vipTop.vue";
  import NoData from "../../components/NoData.vue";
  export default {
    name: "vip",
    components: {
      vipTop,
      NoData,
    },
    data() {
      return {
        pageWidth: oucy.pageWidth,
        bannerHeight: 1000,
        bannerImgHeight: 560,
        bannerImgWidth: 1920,
        screenWidth: 0,
        setSizeCount: 0,
        setSizeTimId: null,
        enterpriseId: null,

        checkAll: "",
        num: 1,
        num2: 1,
        activeName: "first",
        labelPosition: "left",
        isChoose: false,
        isPhone: false,
        isActive: "0",
        dialogVisible: false,
        text: "",
        textarea: "",
        drawer: false,
        tabPosition: "left",
        list: [1, 2, 3],
        size: "660px",
        goodsList: [],
        srcList: [],
        // 头部
        spuName: "",
        searchClassify: "1",
        value: "",
        levelList: [],
        spuId: null,
        // 详情
        detail: {},
        // 选中列表
        select: {},
        // 店内分类列表
        byLtUserAuthAndClassifyCategoryList: [],
        carousel: [],

        queryFurnitureLibraryObj: {},
        // 请求对象
        queryFurnitureLibrary: {
          //   category: 0,
          limit: 200,
          start: 0,
          globalAttrOption: null,
          globalClassId: null,
          goodSort: 0,
          priceMax: null,
          priceMin: null,
        },

        queryFurnitureLibraryObj2: {},
        // 请求对象
        queryFurnitureLibrary2: {
          //   category: 0,
          limit: 20,
          start: 0,
          globalAttrOption: null,
          globalClassId: null,
          goodSort: 2,
          classifyId: null,
          classifyName: "全部分类",
          priceMax: null,
          priceMin: null,
        },
        pageSizes: [20, 40, 100],
        type: 1,
        list: [],
        hotSpu: [],
        recommendSpu: [],
        newSpu: [],
        carouselList: [],
        enterpriseinfo: {},
        hasSearch: false,
        searchSpu: [],
        navType: "spu", //spu home
        spuRankingWeight: [],
        enterpriseCustomer: [],
        loginUser: {},
      };
    },
    mounted() {
      this.enterpriseId = this.$route.query.enterpriseId;
      // 查询企业
      this.findEnterpriseByLtUserAuthId();
      this.setSize();

      window.onresize = () => {
        this.setSize();
      };

      if (this.$route.query.navType) {
        this.navType = this.$route.query.navType;
      }
      if (this.$route.query.classifyId) {
        this.queryFurnitureLibrary2.classifyId = this.$route.query.classifyId;
        this.queryFurnitureLibrary2.classifyName =
          this.$route.query.classifyName;
      }
      // if (this.$route.query.spuName) {
      //   this.spuName = this.$route.query.spuName;
      //   // 查询商品
      //   this.findAllFurnitureSpu();
      //   this.hasSearch = true;
      // } else {
      //   this.queryEnterpriseHomepageSpu();
      //   this.hasSearch = false;
      // }
      // 查询企业轮播图
      this.queryAdEnterprises();
      // 查询店内分类
      this.findByLtUserAuthAndClassifyCategory();

      this.innerSearchSpu();
      this.getSpuRankingWeight();
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    },
    watch: {
      $route: {
        handler(to, from) {
          this.getBreadcrumb();
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      // 切换页数
      sizeChange2: function (pageSize) {
        const me = this;
        me.queryFurnitureLibrary2.limit = pageSize;
        me.innerSearchSpu();
      },
      // 切换页码
      currentChange2: function (current) {
        const me = this;
        me.queryFurnitureLibrary2.start = current - 1;
        me.innerSearchSpu();
      },
      // setSize: function() {
      //     // 通过浏览器宽度(图片宽度)计算高度
      //     this.screenWidth = window.innerWidth;
      //     this.bannerHeight = (this.bannerImgHeight / this.bannerImgWidth) * this.screenWidth
      // },
      setSize() {
        //通过浏览器宽度(图片宽度)计算高度
        if (this.$refs && this.$refs.adImg) {
          this.bannerHeight = this.$refs.adImg.height;
        } else {
          this.screenWidth = window.innerWidth;
          this.bannerHeight =
            (this.bannerImgHeight / this.bannerImgWidth) * this.screenWidth;
          this.setSizeTimId && clearTimeout(this.setSizeTimId);
          this.setSizeTimId = setTimeout(() => {
            ++this.setSizeCount;
            if (this.setSizeCount < 100) {
              this.setSize();
            }
          }, 1000);
        }
      },

      // 获取图片大小
      getImgSize(url) {
        let img = new Image();
        img.onload = () => {
          this.bannerImgHeight = img.height;
          this.bannerImgWidth = img.width;
          this.setSize();
        };
        img.src = url;
      },
      // 获取图片大小
      getImgSize2(url) {
        let img = new Image();
        img.onload = () => {
          this.bannerHeight2 = img.height * (img.width / 1280 - 1);
          console.log("this.bannerHeight2", this.bannerHeight2);
          // this.bannerImgWidth =
        };
        img.src = url;
      },

      handleClose(c) {
        c();
      },
      handleCheckAllChange() {},
      goSearch() {
        if (!this.spuName) {
          this.$message("请输入你要搜索的内容");
        } else {
          this.findAllFurnitureSpu();
          this.hasSearch = true;
        }
      },

      getBreadcrumb() {
        //$route.matched是一个数组 包含当前路由所有嵌套路径的路由记录
        let matched = this.$route.matched.filter((item) => item.name);
        const first = matched[0];
        if (first && first.name !== "首页") {
          matched = [{ path: "/", meta: { title: "首页" } }].concat(matched);
        }
        this.levelList = matched;
        console.log(this.levelList);
      },

      /**
       * 查询店内分类
       * @return {[type]} [description]
       */
      findByLtUserAuthAndClassifyCategory() {
        furnitureClassify
          .findByLtUserAuthAndClassifyCategory({
            enterpriseId: this.enterpriseId,
          })
          .then((res) => {
            console.log("findByLtUserAuthAndClassifyCategory", res);
            if (res && res.length) {
              for (const item of res) {
                item.name = item.classifyName;

                if (
                  item.furnitureClassifyFullList &&
                  item.furnitureClassifyFullList.length
                ) {
                  item.children = item.furnitureClassifyFullList;
                  for (const itemC of item.children) {
                    itemC.name = item.classifyName;
                  }
                }
              }
            }
            this.byLtUserAuthAndClassifyCategoryList = res;
          });
      },

      // 切换页数
      sizeChange: function (pageSize) {
        const me = this;
        me.queryFurnitureLibrary.limit = pageSize;
        me.findAllFurnitureSpu();
      },
      // 切换页码
      currentChange: function (current) {
        const me = this;
        me.queryFurnitureLibrary.start = current - 1;
        me.findAllFurnitureSpu();
      },
      // 获取店铺首页商品集
      // queryEnterpriseHomepageSpu() {
      //   enterpriseinfo
      //     .queryEnterpriseHomepageSpu({ enterpriseId: this.enterpriseId })
      //     .then((res) => {
      //       // this.list.push(...res.hotSpu, ...res.newSpu)
      //       this.hotSpu = res.hotSpu;
      //       this.newSpu = res.newSpu;
      //       this.recommendSpu = res.recommendSpu;
      //     });
      // },
      // 查询企业轮播图
      queryAdEnterprises() {
        adEnterprise
          .queryAdEnterprises({ adImgFlag: 0, enterpriseId: this.enterpriseId })
          .then((res) => {
            if (res) {
              this.carouselList = res;
              this.getImgSize(this.$oucy.ossUrl + this.carouselList[0].adImg);
              this.getImgSize2(this.$oucy.ossUrl + this.carouselList[0].adImg);
            }
          });
      },

      // 查询企业
      findEnterpriseByLtUserAuthId() {
        enterpriseinfo
          .findEnterpriseByLtUserAuthId({ enterpriseId: this.enterpriseId })
          .then((res) => {
            this.enterpriseinfo = res;
            this.$oucy.setTitle(this.enterpriseinfo.enterpriseShopName);
            if (this.enterpriseinfo.vipLeve == 0) {
              this.$oucy.replace(`/Shop?enterpriseId=${this.enterpriseId}`);
            }
            this.queryEnterpriseCustomer();
          });
      },
      // 查询商品
      findAllFurnitureSpu() {
        this.queryFurnitureLibrary.enterpriseId = this.enterpriseId;
        this.queryFurnitureLibrary.spuName = this.spuName;
        this.queryFurnitureLibrary.isShelves = true;
        spu.findAllFurnitureSpu(this.queryFurnitureLibrary).then((res) => {
          this.queryFurnitureLibraryObj = res;
        });
      },
      // changeSpuName() {
      //   if (!this.spuName) {
      //     this.queryEnterpriseHomepageSpu();
      //     this.hasSearch = false;
      //   } else {
      //   }
      // },
      // 排序
      setGoodSort(v) {
        this.queryFurnitureLibrary2.goodSort = v;
        this.innerSearchSpu();
      },
      // 查询企业店铺下的所有产品
      innerSearchSpu() {
        this.queryFurnitureLibrary2.spuName = this.spuName;
        this.queryFurnitureLibrary2.enterpriseId = this.enterpriseId;
        spu.innerSearchSpu(this.queryFurnitureLibrary2).then((res) => {
          this.queryFurnitureLibraryObj2 = res;
        });
      },
      // 查询企业店铺下销量前5的产品
      getSpuRankingWeight() {
        this.queryFurnitureLibrary2.enterpriseId = this.enterpriseId;
        spu.getSpuRankingWeight(this.queryFurnitureLibrary2).then((res) => {
          this.spuRankingWeight = res && res.content ? res.content : null;
        });
      },
      // 点左边分类触发
      setClassify(v) {
        this.queryFurnitureLibrary2.classifyId = v;
        for (const item of this.byLtUserAuthAndClassifyCategoryList) {
          if (item.children && item.children.length) {
            for (const itemC of item.children) {
              if (itemC.id === v) {
                this.queryFurnitureLibrary2.classifyName = itemC.name;
                this.innerSearchSpu();
                break;
              }
            }
          }
        }
      },
      gotoCard() {
        cardEnterprise
          .getCardEnterpriseDetail({ id: this.enterpriseId })
          .then((res) => {
            if (res) {
              this.$oucy.go(`/enterpriseDetail?id=${this.enterpriseId}`);
            } else {
              this.$alert("您还没有创建企业名片，请先使用移动端创建企业名片");
            }
          });
      },
      /**
       * 查询企业客服
       * @param  {[type]} v [description]
       * @return {[type]}   [description]
       */
      queryEnterpriseCustomer() {
        enterpriseinfo
          .queryEnterpriseCustomer({ enterpriseId: this.enterpriseId })
          .then((res) => {
            if (res && res.length) {
              this.enterpriseCustomer = res || [];
            }
          });
      },
      openMessage() {
        this.enterpriseCustomer[0] &&
          this.openImMessage(this.enterpriseCustomer[0]);
      },
      /**
       * 打开聊天框
       * @param  {[type]} targetUserId [description]
       * @return {[type]}              [description]
       */
      openImMessage(v) {
        this.loginUser = localGet("loginUser");
        if (this.loginUser && this.loginUser.id) {
          this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE, {
            targetUserId: v.id,
          });
        } else {
          this.$alert("请先登录", "提示");
        }
      },
      ourShop(v) {
        this.navType = "spu";
        this.queryFurnitureLibrary.start = 0;
        this.spuName = v.keyword;
        // 查询商品
        this.innerSearchSpu();
        console.log(v);
      },
      carouselClick(v, i) {
        if (v.adLinkType) {
          if (v.adLinkType == 1 && v.adLinkUrl) {
            this.$oucy.openNewPage(v.adLinkUrl);
          } else if (v.adLinkType == 2 && v.adLinkUrl) {
            this.$oucy.openNewPage("/product?spuId=" + v.adLinkUrl);
          }
        }
      },
    },
  };
</script>
<style scoped lang="less">
  .price {
    width: 510px;
    height: 105px;
    background: #f8f8f8;
    margin-top: 20px;
    margin-left: 10px;
  }

  .price p {
    width: 28px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-left: 15px;
  }

  .store_kind {
    border-spacing: 0;
    width: 210px;
    /*height: 280px;*/
    // margin-top: 20px;
  }

  .store_kind th,
  td {
    height: 40px;
    border: 1px solid #eaeaea;
    text-align: center;
    line-height: 40px;
  }

  .store_kind th {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: #f8f8f8;
    color: #666666;
    text-align: center;
    line-height: 40px;
  }

  .store_kind .el-button {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    position: relative;
    z-index: 1000;
  }

  .customer_service {
    border-spacing: 0;
    width: 210px;
    // height: 280px;
    margin-top: 20px;
  }

  .customer_service th {
    font-family: Microsoft YaHei;
    height: 40px;
    font-size: 12px;
    font-weight: bold;
    background: #f8f8f8;
    color: #666666;
    border: 1px solid #eaeaea;
    text-align: center;
    line-height: 40px;
  }

  .customer_service td {
    height: 60px;
    border: 1px solid #eaeaea;
    // margin-top: 30px;
    text-align: center;
    line-height: 60px;
  }

  .customer_service img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    float: left;
  }

  .customer_service .el-button {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    position: relative;
    z-index: 1000;
  }

  .sales_rank {
    border-spacing: 0;
    width: 210px;
    margin-top: 20px;
    /*height: 1302px;*/
    background: #ffffff;
    border: 1px solid #efdfca;
    border-collapse: collapse;
    padding-bottom: 20px;
  }

  .sales_rank th {
    width: 210px;
    height: 48px;
    background: linear-gradient(0deg, #fffdf8, #fff6ea);
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }

  .sales_rank td {
    border: none;
  }

  .goods_name {
    width: 188px;
    height: 40px;
    background: #fafafa;
    margin: 0 auto;
    position: relative;
    top: -8px;
  }

  .goods_name span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
    line-height: 40px;
  }

  .goods_name2 {
    width: 188px;
    height: 40px;
    background: #fafafa;
    margin: -5px auto;
  }

  .goods_name2 span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
    line-height: 40px;
  }

  .top {
    width: 820px;
    height: 32px;
    background: #f8f8f8;
    margin-left: 80px;
    margin-top: 20px;
  }

  .top p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    line-height: 11px;
    text-align: center;
    float: left;
    margin-left: 22px;
  }

  .goods_condition {
    width: 650px;
    float: left;
    margin-left: 20px;
  }

  .goods_condition td {
    width: 140px;
    text-align: left;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    border: none;
  }

  .goods_comment /deep/ .el-tabs {
    width: 930px;
    margin-left: 20px;
  }

  .goods_comment /deep/ .el-tabs__item {
    width: 140px;
    height: 39px;
    background: #f3f3f3;
    border: none;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }

  .goods_comment /deep/ .el-tabs__item.is-active {
    background: #2090ff;
    color: white;
  }

  .goods_comment /deep/ .el-tabs__nav-wrap {
    height: 41px;
    background: #2090ff;
  }

  .goods_comment /deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none;
  }

  .goods_comment /deep/ .el-tabs__nav-scroll {
    background-color: #f8f8f8;
  }

  .goods_comment /deep/ .el-form-item__label {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    width: 10px;
  }

  /deep/ .el-divider {
    width: 820px;
    margin-left: 80px;
  }

  .vhtml /deep/ img {
    max-width: 100%;
  }

  .right_button {
    float: right;
    margin-right: 30px;
  }

  .right_button .el-button {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }

  .right_button .el-button:focus {
    font-weight: bold;
    color: #2894ff !important;
  }

  .comment {
    float: right;
    width: 820px;
    margin-right: 30px;
  }

  .comment_1 {
    background: #f8f8f8;
    border-radius: 4px;
  }

  .comment_2 {
    background: #f8f8f8;
    border-radius: 4px;
    margin-top: 10px;
  }

  .comment_3 {
    background: #f8f8f8;
    border-radius: 4px;
    margin-top: 10px;
  }

  .comment_4 {
    background: #f8f8f8;
    border-radius: 4px;
    margin-top: 10px;
  }

  .comment_right {
    width: 760px;
    height: 112px;
    padding: 10px;
    text-align: left;
  }

  .comment_right_p1 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }

  .comment_right_p2 {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #aaaaaa;
    text-align: left;
    position: relative;
  }

  .comment_right span {
    font-size: 14px;
    font-family: SimSun;
    font-weight: 400;
    color: #aaaaaa;
    line-height: 52px;
  }

  .comment_img {
    width: 100%;
    margin-left: -10px;
  }

  .comment_img img {
    margin-left: 10px;
    float: left;
  }

  .comment_img img .active {
    border: 4px #2090ff solid;
  }

  .comment_reply {
    background-image: url("../../assets/background/reply_bk.png");
    background-repeat: no-repeat;
    padding: 30px;
    width: 665px;
  }

  .comment_reply span {
    float: left;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #5b6069;
    line-height: 20px;
    margin-left: -8px;
  }

  .comment_reply p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 4px;
  }

  .comment_reply img {
    margin-top: 0px;
    margin-left: 0px;
  }

  .username {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-left: 10px;
  }

  .footer_footer {
    margin-top: 150px;
  }

  .footer_footer table {
    margin: 0px auto;
  }

  .footer_footer table td {
    border: none;
    text-align: left;
  }

  .footer_footer span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    padding: 10px;
  }

  .drawer_left_top {
    width: 220px;
    height: 64px;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cart_left {
    width: 220px;
    height: 935px;
    margin-top: -20px;
    background-color: #f4f4f4;
  }

  .product_name ul li:hover {
    background: #ffffff;
  }

  .product_name ul li {
    width: 180px;
    height: 96px;
    list-style: none;
    cursor: pointer;
    margin-left: -40px;
    padding: 20px 20px;
  }

  .product_name span {
    line-height: 30px;
  }

  .product_condition {
    text-align: left;
    margin-top: -15px;
  }

  .product_condition p {
    width: 207px;
    height: 29px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  .product_condition .span1 {
    width: 132px;
    height: 12px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #aaaaaa;
  }

  .product_condition .span2 {
    width: 63px;
    height: 12px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ed6732;
  }

  /deep/ .el-input-number__inner {
    width: 20px;
  }

  .fangan_name {
    height: 18px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    float: left;
    padding: 20px;
  }

  /*头部*/
  .topNav {
    background: #fff;
    padding: 10px 0;
  }

  .df-jc-s-b {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    height: 58px;
    width: 163px;
  }

  .input-container {
    width: 500px;
    /*margin: 0 auto;*/
  }

  .topCenter {
    background: url("../../assets/images/product/vipTopBG.png");
    background-size: 100% 100%;
  }

  .topCenterBody {
    height: 261px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: left;
  }

  .merchantLogo {
    width: 128px;
    height: 128px;
    border-radius: 3px;
  }

  .merchantText {
    flex: 1;
  }

  .topBottom {
    width: 100%;
    background: #fff;
  }

  .breadcrumbBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .z-skus {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .z-sku {
    width: 30%;
    padding-right: 10px;
    box-sizing: border-box;
    margin-right: 10px;
  }

  .lisColor {
    box-sizing: border-box;
    border: 3px solid #2090ff;
  }

  .notlisColor {
    box-sizing: border-box;
    border: 3px solid #fff;
  }

  /*头部*/

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .product.topBottom .el-menu.el-menu--horizontal {
    border: none;
    /*font-weight: bold;*/
    font-size: 16px;
    color: #333;
  }

  .product .el-menu-item {
    font-size: 16px;
  }

  .product .el-menu--horizontal > .el-menu-item {
    color: #333;
  }

  .product .el-menu--horizontal > .el-submenu .el-submenu__title {
    font-size: 16px;
    color: #333;
  }

  .left_nav {
    margin-top: 20px;
  }

  .left_nav .el-menu-item {
    /*margin-left:20px;*/
    z-index: 1000;
  }

  .nav img {
    margin-right: 8px;
  }

  .nav:hover .icon1 {
    display: none;
  }

  .nav:hover .icon2 {
    display: inline-block;
  }

  .icon2 {
    display: none;
  }

  .bold_span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    /*margin: 30px 0 0 0px;*/
    /*display: inline-block;*/
    /*padding: 20px;*/
  }

  .top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
  }

  .auth {
    display: flex;
    margin-bottom: 30px;
  }

  .auth > span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
  }

  .auth .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
  }

  .auth .el-button--text.select {
    color: #409eff;
  }

  .el-button--text:hover {
    /*color: rgb(32,144,255) !important;*/
  }

  .el-button--mini {
    border: none;
  }

  .el-button--mini:hover {
    /*background-color: rgb(32,144,255) !important;*/
  }

  /*.el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}*/

  .middle_middle {
    height: 52px;
    background-color: #ffffff;
    margin: 10px 0 10px 10px;
  }

  .middle_middle span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-right: 10px;
  }

  .middle_middle .el-input {
    width: 97px;
    margin-right: 10px;
  }

  .list .el-table__row > td {
    /*去除表格线*/
    border: none;
  }

  .list .el-table th.is-leaf {
    /*去除上边框*/
    border: none;
  }

  .list .el-table::before {
    /*去除下边框*/
    height: 0;
  }

  .wangge {
    float: right;
    margin-right: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .wangge span {
    font-size: 14px;
    margin-left: 8px;
    color: #666666;
  }

  .middle ul li:nth-child(4n) {
    margin-right: 0px;
  }

  .middle ul {
    text-align: left;
  }

  .middle ul li {
    width: 170px;
    height: 340px;
    background-color: #ffffff;
    // padding: 27px;
    /*float: left;*/
    display: inline-block;
    list-style: none;
    cursor: pointer;
  }

  .title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #111111;
    line-height: 1.2;
  }

  .brand_name span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    // float: left;
    margin-right: 5px;
  }

  .price {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #e92121;
    line-height: 50px;
  }

  .stock {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #aaaaaa;
    line-height: 50px;
  }

  .add .el-button--text {
    // box-sizing:border-box;
    width: 100%;
    height: 29px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
    border: 1px solid #2090ff;
    border-radius: 2px;
    margin-top: 15px;
    line-height: 0px;
  }

  .pagination {
    margin-top: 50px;
    line-height: 150px;
  }

  .liebiao {
    min-height: 500px;
  }

  .liebiao ul li {
    width: 850px;
    height: 100px;
    background-color: #ffffff;
    list-style: none;
    margin-left: -20px;
  }

  table {
    text-align: left;
    // margin-top: 15px;
  }

  .liebiao .el-button--text {
    width: 90px;
    height: 29px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
    border: 1px solid #2090ff;
    border-radius: 2px;
    line-height: 0px;
  }

  .grid {
    /*min-height: 500px;*/
  }

  .grid ul {
    // margin-left: -25px;
  }

  .el-page-header__title {
    background-color: red;
  }

  .wangge {
    float: right;
    margin: 15px 30px 0 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .wangge span {
    font-size: 14px;
    margin-left: 8px;
    color: #666666;
  }

  .brand_name {
    text-align: center;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    /*float: left;*/
    height: 15px;
  }

  .pagination {
    margin-top: 50px;
    line-height: 150px;
  }

  /*/deep/ .btn-next{width: 128px;height: 46px!important;background: #FFFFFF!important;}
/deep/ .btn-prev{width: 128px;height: 46px!important;background: #FFFFFF!important;}
/deep/ .el-pager{position: relative;top: 9px;}
/deep/ .number{background: #FFFFFF!important;}
/deep/ .active{background:#2090ff!important ;}*/
  .classifyIcon {
    width: 15px;
    height: 15px;
  }

  .spuImgs {
    width: 219px;
    height: 219px;
    background-color: #f1f1f1;
  }

  .spuImgs2 {
    width: 96px;
    height: 96px;
    background-color: #f1f1f1;
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .el-menu-item,
  .product /deep/ .el-submenu__title {
    font-size: 16px;
    color: #333;
  }

  .carousel img {
    /*设置图片宽度和浏览器宽度一致*/
    width: 100%;
    height: inherit;
  }

  .headline {
    text-align: center;
    margin-top: 10px;
    padding-top: 40px;
    font-size: 24px;
    font-weight: bold;
    color: #323a4a;
    color: #323a4a;
  }

  .headlinetext {
    color: #777e8c;
    font-size: 12px;
  }

  .headline_line {
    display: inline-block;
    width: 24px;
    height: 6px;
    background: #323a4a;
    border-radius: 2px;
    position: relative;
    top: -10px;
  }

  .centerNav {
    padding: 15px 0;
  }

  .nav {
    cursor: pointer;
    flex: 1;
    text-align: center;

    span {
      margin: 0 auto;
      width: 30px;
      height: 6px;
      background: #323a4a;
      border-radius: 2px;
      position: relative;
      display: none;
      top: 16px;
    }

    &.selsct {
      span {
        display: block;
      }
    }
  }

  .right_top {
    display: flex;
    background: #fff;
    padding: 20px;

    .inputprice {
      width: 76px;
    }
  }

  .store_kind .el-button.selsct {
    /*    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;*/
    color: #2090ff;
  }

  .line-h {
    display: inline-block;
    width: 5px;
    height: 16px;
    background: #2090ff;
    border-radius: 1px;
    position: relative;
    top: 2px;
  }

  // 主推产品
  .recommendSpu,
  .hotSpu {
    // width: 219px;
    display: flex;
  }

  .recommendSpu_item1 {
    width: 464px;
    height: 350px;
    margin-right: 30px;
  }

  .recommendSpu_item {
    margin: 0 13px;
  }

  .recommendSpu_item,
  .hotSpu_item,
  .newSpu_item {
    width: 219px;
    background: #fff;
    height: 350px;
  }

  .hotSpu {
    flex-wrap: wrap;
    // margin: 0 -13px;
  }

  .hotSpu_item,
  .newSpu_item {
    width: 218px;
    margin-right: 26px;
    margin-top: 20px;
  }
  .navTypeSpu {
    display: flex;
    /*justify-content: space-between;*/
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 10px;
  }
  .navTypeSpu_items {
    width: 25%;
  }
  .navTypeSpu_item {
    margin: 10px;
  }

  .newSpu {
    display: flex;
  }

  .navTypeSpu {
    display: flex;
    /*justify-content: space-between;*/
    flex-wrap: wrap;
  }

  .alignRight {
    // display: flex;
    flex-direction: row-reverse;
  }

  .topTabsBody {
    background: #323a4a;
    color: #b4b9c5;
  }

  .topTabs {
    margin-left: auto;
    text-align: center;
    margin-right: auto;
    justify-content: center;
    height: 45px;
  }

  .topTab {
    text-align: center;
    justify-content: center;
    flex: 1;

    img {
      margin-right: 20px;
    }

    &:hover {
      background: #384154;
      color: #fff;
    }
  }

  .hotSaleProducts {
    justify-content: space-between;
    // background:#030;
    width: 1280px;
  }

  .hotSaleProduct {
    width: 633px;
    position: relative;
    margin-bottom: 10px;
  }

  .hotSaleProduct + .hotSaleProduct {
    margin-left: 20px;
  }

  .hotSaleProduct_img {
    width: 100%;
  }

  .hotSaleProducts_btm {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    background: rgba(0, 0, 0, 0.5);
    padding: 6px 10px;
    box-sizing: border-box;
  }

  .navTypeSpu_item:nth-child(4n) {
    margin-right: 0;
  }

  .enterpriseShopNamedown {
    background: #363e4f;
    padding: 4px;
  }

  // 认证档案
  .Certifications {
    .Certification-top {
      display: flex;

      .box {
        text-align: center;
        flex: 1;
        background: #f6f7f8;
        border-radius: 2px;
        margin: 5px;
        padding: 20px;

        .box-top {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #2090ff;
        }

        .box-bottom {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #555c68;
          margin-top: 25px;
        }
      }
    }

    .tabTitle {
      font-size: 12px;
      font-family: SimSun;
      font-weight: 400;
      color: #aaaaaa;
    }

    .tag {
      font-size: 12px;
      font-family: SimSun;
      font-weight: 400;
      color: #555c68;
      background: #f6f7f8;
      border-radius: 1px;
      display: inline-block;
      padding: 2px 6px;
      margin-left: 10px;
    }
  }
  .navTypeSpu_item /deep/ .spuImgs {
    width: 233px;
    height: 233px;
  }
  .list4 /deep/ .spuImgs {
    width: 305px;
    height: 305px;
  }
  .list5 /deep/ .spuImgs {
    width: 240px;
    height: 240px;
  }
</style>
